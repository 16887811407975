import React, { useState } from 'react';
import { Link } from 'gatsby'
import { useSiteMetadata } from '../../hooks';
import styles from './Footer.module.scss';
import moment from 'moment';

const Footer = () => {
  const { author: { contacts: { facebook, twitter, instagram } } } = useSiteMetadata();
  return (
    <div className={styles['bottom']}>
      <footer className={styles['footer']}>
        <div className={styles['footer__container']}>
          <div className={styles['footer__row']}>
            <div className={styles['footer__col_logo']}>
              <h2 className={styles['footer__logo_wrap']} style={{color: `white`}}>Oliver Hayman</h2>
              <p className={styles['footer__logo_text']}>Technical SEO Consultant in London</p>
            </div>
            <div className={styles['footer__social_links']}>
              <h4 className={styles['footer__nav_title']}>Follow</h4>
              <div className={styles['footer__nav_items']}>
                <a rel="noopener" href={`https://www.facebook.com/${facebook}`}>Facebook</a>
                <a rel="noopener" href={`https://twitter.com/${twitter}`}>Twitter</a>
                <a rel="noopener" href={`https://www.instagram.com/${instagram}`}>Instagram</a>
              </div>
            </div>
            <div className={styles['footer__main_links']}>
              <h4 className={styles['footer__nav_title_second']} style={{color: `white`}}>Editorial</h4>
                <div className={styles['footer__nav_items']}>
                  <Link to="/">Blog</Link>
                  <Link to="/pages/about">About</Link>
                  <Link to="/pages/contact">Contact</Link>
                </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;



// <p>© All rights reserved {moment().format('YYYY')}</p>
