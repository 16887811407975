// @flow strict
import React, { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { withPrefix } from 'gatsby';
import type { Node as ReactNode } from 'react';
import { useSiteMetadata } from '../../hooks';
import styles from './Layout.module.scss';
import Nav from '../../components/Nav/Nav';
import Footer from '../../components/Footer/Footer';

type Props = {
  children: ReactNode,
  title: string,
  description?: string,
  socialImage? :string
};

const Layout = ({
  children,
  title,
  description,
  socialImage,
  cover_image,
  template,
  date
}: Props) => {
  const { author, url } = useSiteMetadata();
  const metaImage = socialImage != null ? socialImage : author.photo;
  const metaImageUrl = url + withPrefix(metaImage);

  return (
      <>
        <Nav className="nav" />
          <div className={styles.layout}>
            <Helmet>
              <html lang="en" />
              {/* SEO Tags*/}
              <title>{title}</title>
              <meta name="description" content={description} />
              <meta name="robots" content="max-image-preview:large" />
              {/* OGP Tags & Twitter Cards */}
              <meta property="og:site_name" content={title} />
              <meta property="og:image" content={metaImageUrl} />
              <meta name="twitter:card" content="summary_large_image" />
              <meta name="twitter:creator" content="@OliverHayman1" />
              <meta name="twitter:title" content={title} />
              <meta name="twitter:description" content={description} />
              <meta name="twitter:image" content={metaImageUrl} />
              <meta name="twitter:image:alt" content={title} />
            </Helmet>
            {children}
          </div>
        <Footer />
      </>
  );
};

export default Layout;
