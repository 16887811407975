import React from 'react';
import Img from 'gatsby-image';
import { Link, useStaticQuery, graphql, withPrefix } from 'gatsby';
import styled from 'styled-components';
import { useSiteMetadata } from '../../hooks';
import styles from './Logo.module.scss';

const LogoWrap = styled.div`
  margin: auto 0;
  flex: 0 1 39px;
  max-width: 40px;

  @media (max-width: 768px) {
    flex: 0 1 25px;
    max-width: 40px;
  }
`

const Logo = () => {

  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "photo" }, extension: {eq: "jpg"}) {
        childImageSharp {
          fluid(maxWidth: 50, jpegQuality: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <LogoWrap as={Link} to="/">
      <Img className={styles['logo']} fluid={data.file.childImageSharp.fluid} alt="logo" />
    </LogoWrap>
  );
}

export default Logo;
