import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

const NavLinks = () => {
  return (
    <>
      <NavItem to="/">Blog</NavItem>
      <NavItem to="/pages/about">About</NavItem>
      <NavItem to="/pages/contact">Contact</NavItem>
    </>
  );
}


const NavItem = styled(Link)`
  text-decoration: none;
  color: #111;
  white-space: nowrap;
  margin: 0 1vw;
  transition: all 100ms ease-in;
  position: relative;

  :after {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 0%;
    content: ".";
    color: transparent;
    background: #F7A046;
    height: 1px;
    transition: all 0.1s ease-in;
  }

  :hover {
    color: #F7A046;
    ::after {
      width: 100%;
    }
  }

  @media (max-width: 768px) {
    padding: 20px 0;
    font-size: 1.5rem;
    z-index: 6;
  }
`

export default NavLinks;
